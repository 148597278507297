import React, { useEffect, useState } from "react";
import Logo from "../../assets/erorex-Logo.png";
import {
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { getCustomerLedgers, viewInvoice } from "../../apiservices";
import ClientInfo from "../ClientInfo";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: "1px",
}));
function BillPreview({ data, tableArr, clientBalance }) {
  const [billData, setBillData] = useState(null)
  const [qtySum, setQtySum] = useState(0)
  const [amountSum, setAmountSum] = useState(0)
  const [balance, setBalance] = useState(0)
  
  useEffect(() => {
    setInvoice()
  }, [])

  const setInvoice = async () => {
    if (data.id) {
      const ledgerRes = await getCustomerLedgers()
      if(ledgerRes.success){
        const filtered = ledgerRes.data.filter(x => x.id == data.customer_id)
        setBalance((parseInt(filtered[0].opening_balance) + parseInt(filtered[0].debit) - parseInt(filtered[0].credit)))
      }
      const res = await viewInvoice(data.voucher_no)
      if (res.success) {
        setBillData(res.data)
        let sum_q = 0
        let sum_a = 0
        res.data.map((x) => {
          sum_q += parseInt(x.qty)
          sum_a += parseFloat(x.amount)
        })
        setQtySum(sum_q)
        setAmountSum(sum_a)
      }
    }
  }

  return (
    <Container>
      <ClientInfo />
      <Stack justifyContent={"center"} alignItems={"center"}>
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Invoice
        </Typography>
      </Stack>
      <Grid
        container
        style={{ marginBottom: "10px", marginTop: 8 }}
        spacing={1}>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#008890",
              color: "#fff",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}> Seller</Typography>
          </Stack>
          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>{billData && billData.length && `${billData[0].added_by_fname ? billData[0].added_by_fname : ""} ${billData[0].added_by_lname ? billData[0].added_by_lname : ""}`}</Typography>
          </Stack>
        </Grid>

        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#008890",
              color: "#fff",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}> Client</Typography>
          </Stack>
          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {" "}
              {billData && billData.length && billData[0].client_name}
            </Typography>
            {/* <Typography sx={{ mx: 2 }}>
              {" "}
              {(data && data?.selectedClient?.city) || data.city}
            </Typography>
            <Typography sx={{ mx: 2 }}> {data && data.clientNo}</Typography> */}
          </Stack>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#008890",
              color: "#fff",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}>
              Invoice #
            </Typography>
          </Stack>

          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {billData && billData.length && (billData[0].voucher_no.length == 1 ? "Invoice#00" : billData[0].voucher_no.length == 2 ? "Invoice#0" : "Invoice#")}
              {billData && billData.length && billData[0].voucher_no}
            </Typography>
          </Stack>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#008890",
              color: "#fff",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}>Date</Typography>
          </Stack>
          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {" "}
              {data && data.date && data.date}
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Table
        sx={{
          minWidth: 400,
          "& .MuiTableCell-root": {
            border: "1px solid #616161",
          },
        }}
        aria-label="simple table">
        <TableHead
          sx={{
            background: "#E0E0E0",
          }}>
          <StyledTableRow>
            <TableCell align="left">#</TableCell>
            <TableCell align="left" sx={{ width: "120px" }}>
              Item
            </TableCell>

            <TableCell align="left">Quantity</TableCell>
            <TableCell align="left">Price</TableCell>
            <TableCell align="left">Amount</TableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {billData && billData.length &&
            billData.map((item, index) => (
              <StyledTableRow key={item.id} sx={{ height: "50px" }}>
                <TableCell className="bill-heading">{index + 1}</TableCell>
                <TableCell className="bill-heading" sx={{ width: "350px" }}>
                  {item.product_description || item.product_description}
                </TableCell>

                <TableCell className="bill-heading">
                  {item?.qty || item.qty}
                </TableCell>
                <TableCell className="bill-heading">{item?.price}</TableCell>
                <TableCell className="bill-heading">
                  {parseInt(item?.quantity || item.qty) * parseInt(item?.price)}
                </TableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>

      <Stack
        direction={"row"}
        justifyContent={"space-around"}
        alignItems={"center"}
        sx={{ marginTop: "40px" }}
        spacing={2}>
        {/* <Stack alignItems={"center"}>
          <Typography>Sub Total </Typography>
          <Typography>Rs {amountSum}</Typography>
        </Stack> */}
        <Stack alignItems={"center"}>
          <Typography>Total Qty</Typography>
          <Typography>{qtySum}</Typography>
        </Stack>
        <Stack alignItems={"center"}>
          <Typography>Sub Total</Typography>
          <Typography>Rs {amountSum}</Typography>
        </Stack>
        <Stack alignItems={"center"}>
          <Typography>Old Balance </Typography>
          <Typography>Rs {balance - amountSum}</Typography>
        </Stack>
        <Stack alignItems={"center"}>
          <Typography>New Balance </Typography>
          <Typography>Rs {balance}</Typography>
        </Stack>
      </Stack>
    </Container>
  );
}

export default BillPreview;
