import {
  Box,
  Button,
  MenuItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Layout } from "../dashboard/dashboard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataTable from "./usersTable";
import CustomDialogue from "../reuseableComp/Modal";
import { Typo } from "../payment";
import { CustomField } from "../client";
import { PersonLogo } from "../../assets/Svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Autocomplete from "@mui/material/Autocomplete";
import { getData, saveUser, updateUser } from "../../apiservices";
import { toast } from "react-toastify";
import Search from "../searchBar";
const Btn = styled(Button)({
  minWidth: "126px",
  height: "36px",
  padding: "4px",
  borderRadius: "4px",
  textTransform: "none",
  background: "#FFFFFF",
  color: "#008890",
  marginRight: 10,
  "&:hover": {
    background: "#F6F6F6",
  },
  "&:active": {
    background: "#FFFFFF",
  },
});
export const schema = yup
  .object({
    fName: yup.string().required(),
  })
  .required();

const Users = () => {
  const [state, setState] = useState({
    openModal: false,
    usersData: [],
    check: false,
    updatedData: [],
    brandName: "",
    arrayList: [],
    fname: "",
    lname: "",
    username: "",
    password: "",
    contact: "",
    searchData: [],
  });

  const {
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const handleModal = (data = "") => {
    setState((prev) => ({
      ...prev,
      openModal: !prev.openModal,
      check: false,
      updatedData: data,
    }));
    if (data) {
      setState((prev) => ({
        ...prev,
        fname: data.fname,
        lname: data.lname,
        username: data.username,
        contact: data.contact,
      }));
    }
  };
  const handleChange = (e) => {
    let { value } = e.target;
    let { name } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const getUsers = async () => {
    let obj = {
      table: "users"
    };
    let res = await getData(obj);
    if (res.success) {
      setState((prev) => ({
        ...prev,
        usersData: res.data
      }));
    }
    else {
      setState((prev) => ({
        ...prev,
        usersData: []
      }));
    }
  };
  useEffect(() => {
    getUsers();
  }, []);

  const onSubmit = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (state.check) {
      let obj = {
        fname: state.fname,
        lname: state.lname,
        username: state.username,
        contact: state.contact,
        // updated_by: userData.id
      };
      let res = await updateUser(obj, state.updatedData.id);
      if (res) {
        toast.success("User updated successfully");
        getUsers();
        handleModal();
      }
    } else {
      let obj = {
        fname: state.fname,
        lname: state.lname,
        username: state.username,
        password: state.password,
        contact: state.contact,
        created_by: userData.id
      };
      let res = await saveUser(obj);
      if (res) {
        toast.success("User saved successfully");
        getUsers();
        handleModal();
      }
    }
  };
  const handleCheck = () => {
    setState((prev) => ({ ...prev, check: !prev.check }));
  };
  const updateData = (data) => {
    setState((prev) => ({ ...prev, usersData: data }));
  };
  return (
    <Stack sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <>
        <Layout
          justifyContent="space-between"
          alignItems={"center"}
          direction={"row"}>
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "24px",
              fontWeight: 700,
            }}></Typography>
          <Typography sx={{ color: "#FFF", fontSize: "24px", fontWeight: 700 }}>
            Users
          </Typography>
          <Btn onClick={handleModal}>
            <AddCircleIcon fontSize="small" sx={{ mx: 1 }} /> Add new User
          </Btn>
        </Layout>
        <Stack sx={{ padding: "20px" }}>
          <Stack
            justifyContent={"start"}
            alignItems={"start"}
            sx={{
              width: "50%",
            }}>
            {/* <Search
              data={state.searchData}
              updateData={(data) => updateData(data)}
              place1={" Search by City"}
              place2={""}
            /> */}
          </Stack>
          <DataTable
            data={state?.usersData}
            getData={getUsers}
            editUser={(data) => handleModal(data)}
            handleCheck={handleCheck}
          />
        </Stack>
      </>
      <CustomDialogue
        open={state.openModal}
        handleClose={handleModal}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Box sx={{ color: "#008890" }}>
              <PersonLogo />
            </Box>
            <Typo>Add new User</Typo>
          </Stack>
        }
        content={
          <Stack sx={{ padding: 1 }}>
            <Stack gap={2} sx={{ mb: 2 }}>
              <CustomField
                fullWidth
                id="outlined-select-currency"
                label="First Name"
                name="fname"
                value={state.fname}
                onChange={handleChange}
                sx={{
                  background: "#F6F6F6",
                  "& label.Mui-focused": {
                    color: "#008890",
                  },
                }}
              />
              <CustomField
                fullWidth
                id="outlined-select-currency"
                value={state.lname}
                name="lname"
                onChange={handleChange}
                label="Last Name"></CustomField>
              <CustomField
                fullWidth
                id="outlined-select-currency"
                value={state.username}
                name="username"
                onChange={handleChange}
                label="Username"></CustomField>
              {!state.check && <CustomField
                fullWidth
                id="outlined-select-currency"
                value={state.password}
                name="password"
                onChange={handleChange}
                label="Password"
                type="password"
              ></CustomField>}
              <CustomField
                fullWidth
                id="outlined-select-currency"
                value={state.contact}
                name="contact"
                onChange={handleChange}
                label="Contact"></CustomField>
            </Stack>

            <Stack spacing={3} direction={"row"}>
              <Button
                onClick={handleModal}
                size="small"
                sx={{
                  background: "#FFF",
                  width: "200px",
                  textTransform: "none",

                  color: "#9F9E9E",
                  "&:hover": {
                    background: "#EFEFEF",
                  },
                }}>
                Cancel
              </Button>
              <Button
                onClick={onSubmit}
                size="small"
                sx={{
                  width: "200px",
                  textTransform: "none",
                  background: "#008890",
                  color: "#FFF",
                  "&:hover": {
                    background: "#61D140",
                  },
                }}>
                {state.check ? "Update user" : "Save user"}
              </Button>
            </Stack>
          </Stack>
        }
      />
    </Stack>
  );
};

export default Users;
