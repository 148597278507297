import { Button, MenuItem, Stack, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Layout } from "../dashboard/dashboard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataTable from "./ExpenseTable";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CustomDialogue from "../reuseableComp/Modal";
import { BackBtn, CustomField } from "../client";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { getExpenseById, getExpenseLedgers, getData, saveExpense, updateExpense, saveData, updateDataGeneral, getBanks } from "../../apiservices";
import { toast } from "react-toastify";
import { Lay } from "../client";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Back } from "../../assets/Svg";
import SimpleTable from "./SimpleTable";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ReactToPrint from "react-to-print";
import Search from "../searchBar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { formatDate } from "../general";
const Btn = styled(Button)({
  minWidth: "126px",
  height: "36px",
  padding: "4px",
  borderRadius: "4px",
  textTransform: "none",
  background: "#FFFFFF",
  color: "#008890",
  marginRight: 10,
  "&:hover": {
    background: "#F6F6F6",
  },
  "&:active": {
    background: "#FFFFFF",
  },
});
export const Typo = styled(Typography)({
  fontWeight: 700,
  fontSize: "20px",
  color: "#008890",
});
export const schema = yup
  .object({
    name: yup.string().required()
  })
  .required();
const ExpenseHeadsComp = () => {
  const componentRef = React.useRef();
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const CustomTypo = styled(Typography)({
    fontSize: fullScreen ? "12px" : "16px",
  });
  const [accountInfo, setAccountInfo] = useState("")
  const [state, setState] = useState({
    openModal: false,
    openModalAddExpense: false,
    expensesList: [],
    check: false,
    updatedData: [],
    accountsArr: [],
    banksList: [],
    ledgerData: {},
    expenseData: {},
    accId: 0,
    viewExpense: false,
    clientDataById: [],
    searchData: [],
    expense_head_id: 0,
    date_expense: new Date(),
    remarks_expense: '',
    amount_expense: 0
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getBanksList = async () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    let obj = {

    };
    let res = await getBanks(obj);
    if (res.success) {
      let arr = [];
      res.data.map((item) => arr.push(item.bank_name));
      const accInfo = res.data && res.data.length > 0 && res.data.filter((x) => x.id == state.acc_id)[0]
      if (accInfo) {
        setAccountInfo(`${accInfo.account_title} [${accInfo.account_number}]`)
      }
      const arrayWithDuplicates = arr;
      const uniqueArray = [...new Set(arrayWithDuplicates)];
      setState((prev) => ({
        ...prev,
        banksList: res.data,
        uniBanksArr: uniqueArray,
      }));
    }
  };

  const getExpensesList = async () => {
    const submit_data = {
      table: "expenses_heads",
      filters: [
      ],
    }
    let res = await getData(submit_data);
    if (res.success) {
      const data = [];
      for (let i = 0; i < res.data.length; i++) {
        const x = res.data[i];
        const exDataObj = {
          table: "expenses",
          filters: [
            {
              column_name: "expense_head_id",
              operation: "=",
              value: x.id
            }
          ],
        }
        const expenseRes = await getData(exDataObj)
        let ex_sum = 0
        if (expenseRes.success) {
          for (let j = 0; j < expenseRes.data.length; j++) {
            ex_sum += parseFloat(expenseRes.data[j].amount);
          }
        }
        data.push({
          ...x,
          total: ex_sum
        });
      }
      setState((prev) => ({
        ...prev,
        expensesList: data
      }));
    }
    else {
      setState((prev) => ({
        ...prev,
        expensesList: []
      }));
    }
  };
  
  useEffect(() => {
    getExpensesList();
    getBanksList();
  }, []);

  const handleModal = (data = "") => {
    const sBank = state.banksList && state.banksList.length > 0 && state.banksList.filter((x) => x.id == data.acc_id)[0] && state.banksList.filter((x) => x.id == data.acc_id)[0].bank_name ? state.banksList.filter((x) => x.id == data.acc_id)[0].bank_name : ""
    setState((prev) => ({
      ...prev,
      openModal: !prev.openModal,
      check: false,
      updatedData: data,
    }));
    if (data) {
      setValue("name", data.name);
      setValue("description", data.description);
      setValue("opening_balance", data.opening_balance);
    }
    let arr = [];
    let filtered = state.banksList.filter(
      (item) => item.bank_name === sBank
    );
    filtered.map((item) => arr.push(item.address));
    setState((prev) => ({ ...prev, accountsArr: filtered }));
  };

  const handleModalAddExpense = (data = "") => {
    setState((prev) => ({
      ...prev,
      openModalAddExpense: !prev.openModalAddExpense,
      expense_head_id: data.id,
      // check: false,
      // updatedData: data,
    }));
  };

  const submitForm = async (data) => {
    let userData = JSON.parse(localStorage.getItem("user"));
    if (state.check) {
      let obj = {
        name: data.name,
        description: data.description,
        opening_balance: data.opening_balance,
        updated_by: userData.id
      };
      const dataX = {
        table: "expenses_heads",
        data: obj,
        filters: [
          {
            column_name: "id",
            operation: "=",
            value: state.updatedData.id
          }
        ]
      }
      let res = await updateDataGeneral(dataX);
      if (res) {
        toast.success("Expense Head Updated Successfully");
        handleModal();
        getExpensesList();
      }
    } else {
      let obj = {
        name: data.name,
        description: data.description,
        opening_balance: data.opening_balance,
        added_by: userData.id
      };
      const dataX = {
        table: "expenses_heads",
        data: obj,
      }
      let res = await saveData(dataX);
      if (res) {
        toast.success("Expense Head Saved Successfully");
        handleModal();
        getExpensesList();
      }
    }
  };

  const submitFormExpense = async (data) => {
    let userData = JSON.parse(localStorage.getItem("user"));
    let obj = {
      date: new Date().toISOString().split('T')[0],
      transaction_mode: state.selectedMode,
      acc_id: state.selectedMode == "Cash" ? 0 : state.accId,
      expense_head_id: state.expense_head_id,
      remarks: state.remarks_expense,
      amount: state.amount_expense,
      added_by: userData.id
    };
    if(obj.transaction_mode == "Bank" && obj.acc_id == 0){
      toast.error("Fill Bank and Account");
      return
    }
    const dataX = {
      table: "expenses",
      data: obj,
    }
    // console.log("dataX", dataX)
    let res = await saveData(dataX);
    console.log(res)
    if (res) {
      toast.success("Expense saved successfully");
      handleModalAddExpense();
      getExpensesList();
    }
  };

  const handleCheck = () => {
    setState((prev) => ({ ...prev, check: !prev.check }));
  };

  const handleChange = (e) => {
    let { name } = e.target;
    let { value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
    if (name === "selectedBank") {
      let arr = [];
      let filtered = state.banksList.filter((item) => item.bank_name === value);
      filtered.map((item) => arr.push(item.address));
      setState((prev) => ({ ...prev, accountsArr: filtered }));
    }
    if (name === "selectedAcc") {
      // state.banksList.map(
      //   (item) =>
      //     item.account_number === value &&
      //     setState((prev) => ({ ...prev, accId: item.id }))
      // );
      setState((prev) => ({ ...prev, accId: value }))
    }
    // if (name === "selectedClient") {
    //   let filtered = state.clientData.filter(
    //     (item) => item.clientName === value
    //   );
    //   setState((prev) => ({
    //     ...prev,
    //     prevBalance: filtered[0].currentBalance,
    //   }));
    // }
  };

  const viewExpense = async (data = "", modal = true) => {
    if (data.id) {
      // getClientData(data);
      const exDataObj = {
        table: "expenses",
        filters: [
          {
            column_name: "expense_head_id",
            operation: "=",
            value: data.id
          }
        ],
      }
      const expenseRes = await getData(exDataObj)
      // console.log("expenseRes", expenseRes)

      let sum_amount = 0
      let report_data = []
      for (var d = new Date('2024-11-01'); d <= new Date(); d.setDate(d.getDate() + 1)) {
        if (expenseRes && expenseRes.data && expenseRes.data.length) {
          // console.log("d--------------------", formatDate(d));
          expenseRes.data.map((element) => {
            if (formatDate(element.date) == formatDate(d)) {
              report_data.push({
                id: element.id,
                date: element.date,
                transaction_mode: element.transaction_mode,
                acc_id: element.acc_id,
                remarks: element.remarks,
                amount: `Rs ${element.amount}`
              })
              sum_amount += parseFloat(element.amount)
              // console.log(element)
            }
          })
        }
      }

      setState((prev) => ({
        ...prev,
        reportData: report_data,
        amount_expense: sum_amount
      }));
    }
    setState((prev) => ({
      ...prev,
      expenseData: data,
    }));
    if (modal) {
      setState((prev) => ({
        ...prev,
        viewExpense: !prev.viewExpense
      }));
    }
  };

  const ComponentToPrint = React.forwardRef((props, ref) => (
    <Stack ref={ref} id="content-to-print" sx={{ padding: 3 }}>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        direction={"row"}
        sx={{ mt: 2 }}>
        <PersonAddIcon sx={{ mx: 1 }} />
        <Typography sx={{ fontWeight: 600, fontSize: "24px" }}>
          {state.expenseData.expense_name}
        </Typography>
      </Stack>
      <Stack sx={{ mb: 3 }}>
        <Lay
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ paddingLeft: 2, paddingRight: 2 }}>
          <Typography>Expense Head:</Typography>
          <Typography>{state.expenseData.name}</Typography>
        </Lay>
      </Stack>
      <SimpleTable data={state.reportData} viewExpense={viewExpense} getExpensesList={getExpensesList} />
      <Lay
        direction={fullScreen ? "column" : "row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ paddingRight: 2, mt: 1 }}>
        <Stack direction={"row"}></Stack>
        <Stack direction={"row"} justifyContent="end" alignItems={"end"}>
        </Stack>
        <Stack direction={"row"} justifyContent="end" alignItems={"end"}>
        </Stack>
        <Stack direction={"row"} justifyContent="end" alignItems={"end"}>
          <CustomTypo>Total:</CustomTypo>
          <CustomTypo> {`Rs ${state.amount_expense}`}</CustomTypo>
        </Stack>
      </Lay>
    </Stack>
  ));
  const updateData = (data) => {
    setState((prev) => ({ ...prev, expensesList: data }));
  };
  return (
    <Stack sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <>
        {state.viewExpense ? (
          <Stack>
            <Layout
              justifyContent="end"
              alignItems={"center"}
              direction={"row"}>
              <Btn onClick={viewExpense}>
                <Back sx={{ mx: 1 }} />
                {!fullScreen && "Back"}
              </Btn>

              <ReactToPrint
                trigger={() => (
                  <Btn>
                    <PrintIcon fontSize="small" sx={{ mx: 1 }} />{" "}
                    {!fullScreen && "Print"}
                  </Btn>
                )}
                content={() => componentRef.current}
              />
            </Layout>
            <ComponentToPrint ref={componentRef} />
          </Stack>
        ) : (
          <>
            <Layout
              justifyContent="space-between"
              alignItems={"center"}
              direction={"row"}>
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "24px",
                  fontWeight: 700,
                }}></Typography>
              <Typography
                sx={{ color: "#FFF", fontSize: "24px", fontWeight: 700 }}>
                Expenses
              </Typography>
              <Btn onClick={handleModal}>
                <AddCircleIcon fontSize="small" sx={{ mx: 1 }} /> Add new expense
              </Btn>
            </Layout>
            <Stack sx={{ padding: "20px" }}>
              <DataTable
                editExpenseDetails={(data) => handleModal(data)}
                expenseList={state.expensesList}
                handleCheck={handleCheck}
                getData={getExpensesList}
                viewExpense={(data) => viewExpense(data)}
                addExpense={(data) => handleModalAddExpense(data)}
              />
            </Stack>
          </>
        )}
      </>
      <CustomDialogue
        open={state.openModal}
        handleClose={handleModal}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <AccountBalanceWalletIcon
              sx={{ color: "#008890" }}
              fontSize="large"
            />
            <Typo>Add Expense Head</Typo>
          </Stack>
        }
        content={
          <Stack sx={{ padding: 1 }}>
            <CustomField
              fullWidth
              id="exense_name"
              label="Head Name"
              {...register("name")}
              sx={{ mb: 2 }}
            />
            <Typography sx={{ color: "red" }}>
              {errors.name?.message}
            </Typography>

            <CustomField
              fullWidth
              id="expense_description"
              label="Description"
              {...register("description")}
              sx={{ mb: 2 }}
            />
            <Typography sx={{ color: "red" }}>
              {errors.description?.message}
            </Typography>

            <CustomField
              fullWidth
              id="expense_opening_balance"
              label="Opening Balance"
              type="number"
              {...register("opening_balance")}
              sx={{ mb: 2 }}
            />
            <Typography sx={{ color: "red" }}>{errors.opening_balance?.message}</Typography>

            <Stack spacing={3} direction={"row"} sx={{ mt: 3 }}>
              <Button
                size="small"
                onClick={handleSubmit(submitForm)}
                sx={{
                  width: "250px",
                  textTransform: "none",
                  background: "#008890",
                  color: "#FFF",
                  "&:hover": {
                    background: "#61D140",
                  },
                }}>
                {state.check ? "update expense details" : " Save Expense Details"}
              </Button>
              <Button
                onClick={handleModal}
                size="small"
                sx={{
                  background: "#FFF",
                  width: "250px",
                  textTransform: "none",

                  color: "#9F9E9E",
                  "&:hover": {
                    background: "#EFEFEF",
                  },
                }}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        }
      />
      <CustomDialogue
        open={state.openModalAddExpense}
        handleClose={handleModalAddExpense}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <AccountBalanceWalletIcon
              sx={{ color: "#008890" }}
              fontSize="large"
            />
            <Typo>Add Expense</Typo>
          </Stack>
        }
        content={
          <Stack sx={{ padding: 1 }}>
            {/* <CustomField
              fullWidth
              id="date_expense"
              label="Date"
              onChange={(e)=>{
                setValue("date_expense", e.target.value);
              }}
              sx={{ mb: 2 }}
              type="date"
            />
            <Typography sx={{ color: "red" }}>
              {errors.date?.message}
            </Typography> */}

            <CustomField
              fullWidth
              id="outlined-select-currency"
              select
              label="Select Transaction Mode"
              name="selectedMode"
              value={state.selectedMode}
              onChange={handleChange}
              sx={{ mb: 2 }}>
              {["Cash", "Bank"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </CustomField>
            {state.selectedMode === "Bank" && (
              <>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Bank Name"
                  name="selectedBank"
                  value={state.selectedBank}
                  onChange={handleChange}
                  sx={{ mb: 2 }}>
                  {state.uniBanksArr.map((option) => (
                    <MenuItem key={option} value={option}>
                      {`${option}`}
                    </MenuItem>
                  ))}
                </CustomField>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Select Account"
                  name="selectedAcc"
                  value={state.selectedAcc}
                  onChange={handleChange}
                  sx={{ mb: 2 }}>
                  {state.accountsArr.map((option, id) => (
                    <MenuItem
                      key={option.account_number}
                      value={option.id}>
                      {`${option.account_title} [${option.account_number}]`}
                    </MenuItem>
                  ))}
                </CustomField>
              </>
            )}

            <CustomField
              fullWidth
              id="remarks_expense"
              label="Remarks"
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  remarks_expense: e.target.value,
                }));
              }}
              sx={{ mb: 2 }}
            />
            <Typography sx={{ color: "red" }}>
              {errors.remarks_expense?.message}
            </Typography>

            <CustomField
              fullWidth
              id="amount_expense"
              label="Amount"
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  amount_expense: e.target.value,
                }));
              }}
              sx={{ mb: 2 }}
              type="number"
            />
            <Typography sx={{ color: "red" }}>
              {errors.amount_expense?.message}
            </Typography>



            <Stack spacing={3} direction={"row"} sx={{ mt: 3 }}>
              <Button
                size="small"
                onClick={submitFormExpense}
                sx={{
                  width: "250px",
                  textTransform: "none",
                  background: "#008890",
                  color: "#FFF",
                  "&:hover": {
                    background: "#61D140",
                  },
                }}>
                {state.check ? "update expense details" : " Save Expense Details"}
              </Button>
              <Button
                onClick={handleModalAddExpense}
                size="small"
                sx={{
                  background: "#FFF",
                  width: "250px",
                  textTransform: "none",

                  color: "#9F9E9E",
                  "&:hover": {
                    background: "#EFEFEF",
                  },
                }}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        }
      />
    </Stack>
  );
};

export default ExpenseHeadsComp;
