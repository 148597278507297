import { Stack, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getClient,
  getCustomerLedgers,
  getDailyPayment,
  getDailyProfitLoss,
  getDailySales,
  getData,
  getStock,
  getTotalCurrentStock,
} from "../../apiservices";
import Sale from "../../assets/sale.png";
import Payment from "../../assets/payment.png";
import Report from "../../assets/report.png";
import Profit from "../../assets/profit.png";
import Stock from "../../assets/stock.png";
import Transaction from "../../assets/transaction.png";
import { useDispatch } from "react-redux";
import { handleSelectedCom } from "../../sidebar/toggleSlice";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { formatDate } from "../general";
export const Layout = styled(Stack)({
  width: "100%",
  height: "64px",
  marginTop: "10px",
  borderRadius: "8px",
  background: "#008890",
});
export const RoundBox = styled(Stack)({
  padding: 4,
  backgroundColor: "#008890",
  height: "175px",
  minWidth: "250px",
  borderRadius: "25px",
  ":hover": {
    transform: "scale3d(1.05, 1.05, 1)",
    boxShadow: "0 10px 10px #fff",
  },
});

function Dashboard() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [state, setState] = useState({
    dailySale: 0,
    dailyPurchase: 0,
    dailyPayment: 0,
    dailySupplierPayment: 0,
    dailyLoss: 0,
    dailyProfit: 0,
    dailyStock: 0,
    totalClientsBalance: 0,
    totalCash: 0,
    clientData: [],
  });

  const dispatch = useDispatch();
  const handleSelection = (arg) => {
    dispatch(handleSelectedCom(arg));
  };

  const getPayment = async () => {
    let obj = {
      table: "payments"
    };
    let res = await getData(obj);
    if (res.success) {
      let sum = 0
      res.data.map((x) => {
        if (x.date == new Date().toISOString().split("T")[0]) {
          // console.log(x)
          sum += parseFloat(x.amount)
        }
      })
      setState((prev) => ({ ...prev, dailyPayment: sum }));
    }
  };

  const getSupplierPayment = async () => {
    let obj = {
      table: "supplier_payments"
    };
    let res = await getData(obj);
    if (res.success) {
      let sum = 0
      res.data.map((x) => {
        if (x.date == new Date().toISOString().split("T")[0]) {
          // console.log(x)
          sum += parseFloat(x.amount)
        }
      })
      setState((prev) => ({ ...prev, dailySupplierPayment: sum }));
    }
  };

  const getCurrentStock = async () => {
    let res = await getStock();
    if (res.success) {
      let sum = 0
      res.data.map((x) => {
        sum += (parseInt(x.opening_qty) + parseInt(x.stock_in) - parseInt(x.stock_out))
      })
      setState((prev) => ({ ...prev, dailyStock: sum }));
    }
  };

  const getSales = async () => {

    let obj = {
      table: "sales"
    };
    let res = await getData(obj);
    if (res.success) {
      let sumSale = 0
      res.data.map((x) => {
        if (x.date == new Date().toISOString().split("T")[0]) {
          // console.log(x)
          sumSale += x.qty * x.price
        }
      })
      setState((prev) => ({ ...prev, dailySale: sumSale }));
    }
  };

  const getPurchases = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    let obj = {
      table: "purchases"
    };
    let res = await getData(obj);
    if (res.success) {
      let sumPurchase = 0
      res.data.map((x) => {
        if (x.date == new Date().toISOString().split("T")[0]) {
          // console.log(x)
          sumPurchase += x.qty * x.price
        }
      })
      setState((prev) => ({ ...prev, dailyPurchase: sumPurchase }));
    }
  };

  const getClientsData = async () => {
    let res = await getCustomerLedgers();
    if (res.success) {
      let sum = 0
      res.data.map((x) => {
        sum += parseFloat(x.opening_balance) + parseFloat(x.debit) - parseFloat(x.credit)
      })
      setState((prev) => ({ ...prev, clientData: res.data, totalClientsBalance: sum }));
    }
  };

  const getCashReport = async () => {
    const cpDataObj = {
      table: "payments",
      filters: [
        {
          column_name: "transaction_mode",
          operation: "=",
          value: 'Cash'
        }
      ],
    }
    const cPaymentRes = await getData(cpDataObj)
    // console.log("cPaymentRes", cPaymentRes)

    const spDataObj = {
      table: "supplier_payments",
      filters: [
        {
          column_name: "transaction_mode",
          operation: "=",
          value: 'Cash'
        }
      ],
    }
    const sPaymentRes = await getData(spDataObj)
    // console.log("sPaymentRes", sPaymentRes)

    let balance = []
    // for (var d = new Date(new Date(state.startDate).toISOString().split("T")[0]); d <= new Date(new Date(state.endDate).toISOString().split("T")[0]); d.setDate(d.getDate() + 1)) {
    for (var d = new Date('2024-03-01'); d <= new Date(); d.setDate(d.getDate() + 1)) {
      if (cPaymentRes && cPaymentRes.data && cPaymentRes.data.length) {
        // console.log("d--------------------", formatDate(d));
        cPaymentRes.data.map((element) => {
          if (formatDate(element.date) == formatDate(d)) {
            balance.push({
              date: element.date,
              details: `CPayment#00${element.id}`,
              debit: element.amount,
              credit: 0
            })
            // console.log(element)
          }
        })
      }
      if (sPaymentRes && sPaymentRes.data && sPaymentRes.data.length) {
        sPaymentRes.data.map((element) => {
          if (formatDate(element.date) == formatDate(d)) {
            balance.push({
              date: element.date,
              details: `SPayment#00${element.id}`,
              debit: 0,
              credit: element.amount
            })
            // console.log(element)
          }
        })
      }
    }

    let sum_debit = 0
    let sum_credit = 0
    if (balance && balance.length) {
      for (let i = 0; i < balance.length; i++) {
        sum_debit += parseFloat(balance[i].debit)
        sum_credit += parseFloat(balance[i].credit)
        if (i == 0) {
          balance[i].closing = parseFloat(balance[i].debit) - parseFloat(balance[i].credit)
        }
        else {
          balance[i].closing = parseFloat(balance[i - 1].closing) + parseFloat(balance[i].debit) - parseFloat(balance[i].credit)
        }
      }
    }

    // console.log("balance", balance)

    setState((prev) => ({
      ...prev,
      totalCash: sum_debit - sum_credit
    }));
  };

  useEffect(() => {
    getPurchases();
    getSales();
    getPayment();
    getSupplierPayment();
    getCurrentStock();
    getClientsData()
    getCashReport()
  }, []);

  return (
    <Stack alignItems={"center"} sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <Layout justifyContent="center" alignItems={"center"}>
        <Typography sx={{ color: "#FFF", fontSize: "24px", fontWeight: 700 }}>
          Dashboard
        </Typography>
      </Layout>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ mt: 10 }}
        spacing={5}>
        <Stack container direction={fullScreen ? "column" : "row"} spacing={10}>
          {/* <RoundBox onClick={() => handleSelection("stock")}>
            <Stack alignItems={"start"} sx={{ padding: 2 }}>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                Today Purchase
              </Typography>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                {state.dailyPurchase}
              </Typography>
            </Stack>
            <Stack alignItems={"end"}>
              <img
                src={Sale}
                alt=""
                width={"40px"}
                style={{ marginRight: 5 }}
              />
            </Stack>
          </RoundBox> */}
          <RoundBox onClick={() => handleSelection("transaction")}>
            <Stack alignItems={"start"} sx={{ padding: 2 }}>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                Transaction
              </Typography>
            </Stack>
            <Stack alignItems={"end"}>
              <img
                src={Transaction}
                alt=""
                width={"40px"}
                style={{ marginRight: 5 }}
              />
            </Stack>
          </RoundBox>
          <RoundBox onClick={() => handleSelection("sale")}>
            <Stack alignItems={"start"} sx={{ padding: 2 }}>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                Today Sale
              </Typography>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                {state.dailySale}
              </Typography>
            </Stack>
            <Stack alignItems={"end"}>
              <img
                src={Sale}
                alt=""
                width={"40px"}
                style={{ marginRight: 5 }}
              />
            </Stack>
          </RoundBox>
          <RoundBox onClick={() => handleSelection("payment")}>
            <Stack alignItems={"start"} sx={{ padding: 2 }}>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                Today Payment
              </Typography>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                {state.dailyPayment}
              </Typography>
            </Stack>
            <Stack alignItems={"end"}>
              <img
                src={Payment}
                alt=""
                width={"40px"}
                style={{ marginRight: 5 }}
              />
            </Stack>
          </RoundBox>
        </Stack>
        <Stack direction={fullScreen ? "column" : "row"} spacing={10}>
          {/* <RoundBox onClick={() => handleSelection("payment")}>
            <Stack alignItems={"start"} sx={{ padding: 2 }}>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                Today Supplier Payment
              </Typography>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                {state.dailySupplierPayment}
              </Typography>
            </Stack>
            <Stack alignItems={"end"}>
              <img
                src={Payment}
                alt=""
                width={"40px"}
                style={{ marginRight: 5 }}
              />
            </Stack>
          </RoundBox> */}
          <RoundBox onClick={() => handleSelection("stockSheet")}>
            <Stack alignItems={"start"} sx={{ padding: 2 }}>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                Current Stock
              </Typography>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                {state.dailyStock}
              </Typography>
            </Stack>
            <Stack alignItems={"end"}>
              <img
                src={Stock}
                alt=""
                width={"40px"}
                style={{ marginRight: 5 }}
              />
            </Stack>
          </RoundBox>
          <RoundBox onClick={() => handleSelection("clientSheet")}>
            <Stack alignItems={"start"} sx={{ padding: 2 }}>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                Client Report
              </Typography>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                {state.totalClientsBalance}
              </Typography>
            </Stack>
            <Stack alignItems={"end"}>
              <img
                src={Report}
                alt=""
                width={"40px"}
                style={{ marginRight: 5 }}
              />
            </Stack>
          </RoundBox>
          <RoundBox onClick={() => handleSelection("profit")}>
            <Stack alignItems={"start"} sx={{ padding: 2 }}>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                Profit / Loss
              </Typography>
            </Stack>
            <Stack alignItems={"end"}>
              <img
                src={Profit}
                alt=""
                width={"40px"}
                style={{ marginRight: 5 }}
              />
            </Stack>
          </RoundBox>
        </Stack>
        {/* <Stack direction={fullScreen ? "column" : "row"} spacing={10}>
          <RoundBox onClick={() => handleSelection("cash")}>
            <Stack alignItems={"start"} sx={{ padding: 2 }}>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                Cash Report
              </Typography>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                {state.totalCash}
              </Typography>
            </Stack>
            <Stack alignItems={"end"}>
              <img
                src={Transaction}
                alt=""
                width={"40px"}
                style={{ marginRight: 5 }}
              />
            </Stack>
          </RoundBox>
        </Stack> */}
      </Stack>
    </Stack>
  );
}

export default Dashboard;
