import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { getBills, getClient } from "../../apiservices";
import { Stack } from "@mui/material";
import { Layout } from "../dashboard/dashboard";
import ReactToPrint from "react-to-print";
import { Btn } from "../payment";
import PrintIcon from "@mui/icons-material/Print";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#008890",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function SimpleTable({ data, clientData, ledgerData }) {
  const [state, setState] = React.useState({
    addItem: false,
    viewBill: false,
    tableArr: [],
    viewBillModal: false,
    billData: {},
    rowData: [],
    salesData: {},
    billsLength: 0,
    viewBillData: {},
    itemsArr: [],
    searchData: [],
    customData: {},
    clientData: [],
    clientBalance: 0,
    arr: [],
  });
  // console.log(ledgerData)
  const convertDate = (dates) => {
    const seconds = dates; // Assuming the value is in seconds
    const milliseconds = seconds * 1000; // Convert seconds to milliseconds
    const date = moment(milliseconds);

    return date.format("DD-MM-YYYY");
  };
  const getClientsData = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    let obj = {
      company_code: userData.company_code,
    };
    let res = await getClient(obj);
    if (res.success) {
      setState((prev) => ({ ...prev, clientData: res.data }));
    }
  };
  const getBillData = async () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    let obj = {
      
    };
    let res = await getBills(obj);
    if(res.success){
      res.data.map((x) => {
        if(x.voucher_no.length == 1){
          x.show_voucher_no = `Invoice#00${x.voucher_no}`
        }
        else if(x.voucher_no.length == 2){
          x.show_voucher_no = `Invoice#0${x.voucher_no}`
        }
        else{
          x.show_voucher_no = `Invoice#${x.voucher_no}`
        }
      })
      setState((prev) => ({
        ...prev,
        rowData: res.data,
      }))
    }
    else{
      setState((prev) => ({
        ...prev,
        rowData: [],
      }))
    }
  };
  const viewBills = async (data = "") => {
    getClientsData();
    setState((prev) => ({
      ...prev,
      viewBill: !prev.viewBill,
    }));
    if (data) {
      let obj = {};
      let res = await getClient(obj);
      if (res.success) {
        let filtered = res.data.filter(
          (item) => item.client_name === data.client
        );
        setState((prev) => ({
          ...prev,
          clientBalance: filtered[0]?.opening_balance,
          viewBillData: data,
          // tableArr: data.saveSale
          //   ? [...data?.items, ...data?.saveSale]
          //   : data?.items,
        }));
      }
    }
  };
  return (
    <>
    {state.viewBill ? (
      <>
        {/* <Layout justifyContent="end" alignItems={"center"} direction={"row"}>
          <Stack direction={"row"}>

            <ReactToPrint
              trigger={() => (
                <Btn>
                  <PrintIcon fontSize="small" sx={{ mx: 1 }} /> Print
                </Btn>
              )}
              
              content={() => ViewBillRef.current}
            />
            <Btn onClick={viewBills}>
              <Back sx={{ mx: 1 }} /> Back
            </Btn>
          </Stack>
        </Layout>
        <Stack id="content-to-print">
          <ComponentToPrint ref={ViewBillRef} />
        </Stack> */}
      </>
    ) :
    (<TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Date</StyledTableCell>
            <StyledTableCell align="left">Details</StyledTableCell>
            <StyledTableCell align="left">Debit</StyledTableCell>
            <StyledTableCell align="left">Credit</StyledTableCell>
            <StyledTableCell align="left">Balance</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ledgerData?.map((row) => (
            <StyledTableRow key={1}>
              <StyledTableCell align="left">
                {row.date ? moment(row.date).format("DD-MM-YYYY") : "-"}
              </StyledTableCell>
              <StyledTableCell align="left">
                {`${row.details}`}
              </StyledTableCell>
              <StyledTableCell align="left">
                {row.debit}
              </StyledTableCell>
              <StyledTableCell align="left">{`${
                row.credit
              }`}</StyledTableCell>
              <StyledTableCell align="left">
                {row.closing}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>)}
    </>
  );
}
