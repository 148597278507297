import { Button, MenuItem, Stack, Typography, styled, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../dashboard/dashboard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataTable from "./CashWithdrawalTable";
import CashWithdrawalsIcon from "@mui/icons-material/Payment";
import CustomDialogue from "../reuseableComp/Modal";
import { CustomField } from "../client";
import { Back } from "../../assets/Svg";
import PrintIcon from "@mui/icons-material/Print";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import ReactToPrint from "react-to-print";

import {
  getBanks,
  getClient,
  getCustomerLedgers,
  getData,
  saveData,
  updateDataGeneral,
} from "../../apiservices";
import { toast } from "react-toastify";
import Search from "../searchBar";
import moment from "moment";
import ClientInfo from "../ClientInfo";
export const Btn = styled(Button)({
  minWidth: "126px",
  height: "36px",
  padding: "4px",
  borderRadius: "4px",
  textTransform: "none",
  background: "#FFFFFF",
  color: "#008890",
  marginRight: 10,
  "&:hover": {
    background: "#F6F6F6",
  },
  "&:active": {
    background: "#FFFFFF",
  },
});
export const Typo = styled(Typography)({
  fontWeight: 700,
  fontSize: "20px",
  color: "#008890",
});
export const Lay = styled(Stack)({
  // borderRadius: "8px",
  padding: 15,
  border: "1px solid #9F9E9E",
});
const CashWithdrawal = () => {
  const theme = useTheme();
  const componentRef = useRef();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [accountInfo, setAccountInfo] = useState("")
  const [receivedBy, setRecievedBy] = useState("")
  const [state, setState] = useState({
    openModal: false,
    viewCashWithdrawal: false,
    banksList: [],
    selectedBank: "",
    amount: 0,
    viewLedger: false,
    cashWithdrawals: {},
    cashWithdrawalData: [],
    accNum: "",
    accId: 0,
    currentBalance: 0,
    accountsArr: [],
    uniBanksArr: [],
    selectedAcc: {},
    check: false,
    updatedData: {},
  });

  const getBanksList = async () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    let obj = {

    };
    let res = await getBanks(obj);
    if (res.success) {
      let arr = [];
      res.data.map((item) => arr.push(item.bank_name));
      const accInfo = res.data && res.data.length > 0 && res.data.filter((x) => x.id == state.cashWithdrawals.acc_id)[0]
      if (accInfo) {
        setAccountInfo(`${accInfo.account_title} [${accInfo.account_number}]`)
      }
      const arrayWithDuplicates = arr;
      const uniqueArray = [...new Set(arrayWithDuplicates)];
      setState((prev) => ({
        ...prev,
        banksList: res.data,
        uniBanksArr: uniqueArray,
      }));
    }
  };

  useEffect(() => {
    getCashWithdrawals();
  }, [state.banksList])

  useEffect(() => {
    getBanksList();
  }, [state.cashWithdrawals])

  useEffect(() => {
    getUsers();
  }, [state.cashWithdrawals])

  const getUsers = async () => {
    const data = {
      table: "users"
    }
    const res = await getData(data)
    if (res.success) {
      const user = res.data && res.data.length && res.data.filter((x) => x.id == state.cashWithdrawals.added_by)[0]
      if (user) {
        setRecievedBy(`${user.fname} ${user.lname}`)
      }
      else {
        setRecievedBy("")
      }
    }
  }

  const getCashWithdrawals = async () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    const obj = {
      table: "cash_withdrawals",
      // filters: [
      //   {
      //     column_name: "company_code",
      //     operation: "=",
      //     value: dataX.company_code
      //   }
      // ],
    }
    let res = await getData(obj);
    if (res.success) {
      res.data.map((x) => {
        if (x.id.length == 1) {
          x.show_id = `Withdrawal#00${x.id}`
        }
        else if (x.id.length == 2) {
          x.show_id = `Withdrawal#0${x.id}`
        }
        else {
          x.show_id = `Withdrawal#${x.id}`
        }
        if (state.banksList && state.banksList.length) {
          x.bank_name = state.banksList.filter(bank => bank.id === x.acc_id)[0].bank_name
          const acc_number = state.banksList.filter(bank => bank.id === x.acc_id)[0].account_number
          const acc_title = state.banksList.filter(bank => bank.id === x.acc_id)[0].account_title
          x.account = `${acc_title} [${acc_number}]`
        }
      })
      setState((prev) => ({
        ...prev,
        cashWithdrawalData: res.data.sort((a, b) => b.id - a.id),
      }))
    }
    else {
      setState((prev) => ({
        ...prev,
        cashWithdrawalData: [],
      }));
    }
  };

  const handleModal = (data = "") => {
    if (data.amount) {
      setState((prev) => ({ ...prev, check: true }));
    }
    const sBank = state.banksList && state.banksList.length > 0 && state.banksList.filter((x) => x.id == data.acc_id)[0] && state.banksList.filter((x) => x.id == data.acc_id)[0].bank_name ? state.banksList.filter((x) => x.id == data.acc_id)[0].bank_name : ""
    setState((prev) => ({
      ...prev,
      openModal: !prev.openModal,
      selectedBank: sBank,
      selectedAcc: data?.acc_id && `${data?.acc_id}`,
      updatedData: data,
      amount: data?.amount,
    }));
    let arr = [];
    let filtered = state.banksList.filter(
      (item) => item.bank_name === sBank
    );
    filtered.map((item) => arr.push(item.address));
    setState((prev) => ({ ...prev, accountsArr: filtered }));
  };

  const view = async (data = "") => {
    setState((prev) => ({
      ...prev,
      viewCashWithdrawal: !prev.viewCashWithdrawal,
    }));
    if (data.id) {
      setState((prev) => ({
        ...prev,
        cashWithdrawals: data,
      }));
      // const ledgerRes = await getCustomerLedgers()
      // if (ledgerRes.success) {
      //   const filtered = ledgerRes.data.filter(x => x.id == data.client_id)
      //   if (filtered && filtered.length) {
      //     setState((prev) => ({
      //       ...prev,
      //       currentBalance: (parseInt(filtered[0].opening_balance) + parseInt(filtered[0].debit) - parseInt(filtered[0].credit)),
      //     }));
      //   }
      // }
    }
  };

  const handleChange = (e) => {
    let { name } = e.target;
    let { value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
    if (name === "selectedBank") {
      let arr = [];
      let filtered = state.banksList.filter((item) => item.bank_name === value);
      filtered.map((item) => arr.push(item.address));
      setState((prev) => ({ ...prev, accountsArr: filtered }));
    }
    if (name === "selectedAcc") {
      state.banksList.map(
        (item) =>
          item.account_number === value &&
          setState((prev) => ({ ...prev, accId: item.id }))
      );
      setState((prev) => ({ ...prev, accId: value }))
    }
  };

  const saveCashWithdrawal = async () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    if (state.check) {
      let obj = {
        table: "cash_withdrawals",
        data: {
          date: new Date().toISOString().split('T')[0],
          acc_id: state.accId,
          amount: state.amount,
          added_by: userData.id,
        },
        filters: [
          {
            column_name: "id",
            operation: "=",
            value: state.updatedData.id
          }
        ]
      };
      let res = await updateDataGeneral(obj);
      if (res.success) {
        toast.success("CashWithdrawal updated successfully");
        handleModal();
        getCashWithdrawals();
      }
      else {
        console.log(res)
        toast.error("Something went wrong");
      }
    } else {
      let obj = {
        table: "cash_withdrawals",
        data: {
          date: new Date().toISOString().split('T')[0],
          acc_id: state.accId,
          amount: state.amount,
          added_by: userData.id,
        }
      };
      let res = await saveData(obj);
      if (res.success) {
        handleModal();
        await getCashWithdrawals();
        toast.success("CashWithdrawal saved successfully");
      }
      else {
        console.log(res)
        toast.error("Something went wrong");
      }
    }
  }
  const updateData = (data) => {
    setState((prev) => ({ ...prev, cashWithdrawalData: data }));
  };
  const ComponentToPrint = React.forwardRef((props, ref) => (
    <Stack
      width={"98%"}
      sx={{
        padding: 1,
      }}
      ref={ref}
      id="content-to-print">
      <ClientInfo />
      <Stack justifyContent={"center"} alignItems={"center"}>
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Cash Withdrawals
        </Typography>
      </Stack>
      <Grid
        container
        style={{ marginBottom: "10px", marginTop: 8 }}
        spacing={1}>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#008890",
              color: "#fff",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}>
              {" "}
              Payer:
            </Typography>
          </Stack>
          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {receivedBy}
            </Typography>
          </Stack>
        </Grid>

        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#008890",
              color: "#fff",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}> Recieved By:</Typography>
          </Stack>
          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {state.clientData && state.clientData.length && state.clientData.filter((x) => x.id == state.cashWithdrawals.client_id)[0].client_name}
            </Typography>
          </Stack>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#008890",
              color: "#fff",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}>
              CashWithdrawal #
            </Typography>
          </Stack>

          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {state.cashWithdrawals && (state.cashWithdrawals.id.length == 1 ? "CashWithdrawal#00" : state.cashWithdrawals.id.length == 2 ? "CashWithdrawal#0" : "CashWithdrawal#")}
              {state.cashWithdrawals.id}
            </Typography>
          </Stack>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#008890",
              color: "#fff",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}>Date</Typography>
          </Stack>
          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {" "}
              {state.cashWithdrawals.date}
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Lay
        direction={"row"}
        spacing={2}
        justifyContent={"space-between"}
        sx={{ paddingLeft: 2, paddingRight: 2, marginTop: 4 }}
        alignItems={"center"}>
        <Typography>Transaction Mode:</Typography>
        <Typography>
          {state.cashWithdrawals.transaction_mode === "Bank"
            ? state.banksList && state.banksList.length > 0 && state.banksList.filter((x) => x.id == state.cashWithdrawals.acc_id)[0] && state.banksList.filter((x) => x.id == state.cashWithdrawals.acc_id)[0].bank_name ? state.banksList.filter((x) => x.id == state.cashWithdrawals.acc_id)[0].bank_name : ""
            : state.cashWithdrawals.transaction_mode}
        </Typography>
      </Lay>
      {state.cashWithdrawals.transaction_mode === "Bank" && (
        <Lay
          direction={"row"}
          spacing={2}
          justifyContent={"space-between"}
          sx={{ paddingLeft: 2, paddingRight: 2 }}
          alignItems={"center"}>
          <Typography>Account Title & Number :</Typography>
          <Typography>{accountInfo}</Typography>
        </Lay>
      )}
      <Lay
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ paddingLeft: 2, paddingRight: 2 }}
        alignItems={"center"}>
        <Typography>Amount:</Typography>
        <Typography>{state.cashWithdrawals.amount}</Typography>
      </Lay>
      <Lay
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ paddingLeft: 2, paddingRight: 2 }}
        alignItems={"center"}>
        <Typography>Current Balance:</Typography>
        <Typography>{state.currentBalance}</Typography>
      </Lay>
    </Stack>
  ));
  const handleCheck = () => {
    setState((prev) => ({ ...prev, check: !prev.check }));
  };
  const handleOnSearch = (string) => {
    setState((prev) => ({ ...prev, selectedClient: string }));
  };
  const handleOnSelect = (item) => {
    setState((prev) => ({ ...prev, selectedClient: item.clientName }));
  };
  const handleClientChange = (e, value) => {
    if (value) {
      setState((prev) => ({
        ...prev,
        selectedClient: value,
      }));
    }
  };

  return (
    <Stack sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <>
        {!state.viewCashWithdrawal ? (
          <>
            <Layout
              justifyContent="space-between"
              alignItems={"center"}
              direction={"row"}>
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "24px",
                  fontWeight: 700,
                }}></Typography>
              <Typography
                sx={{ color: "#FFF", fontSize: "24px", fontWeight: 700 }}>
                Cash Withdrawals
              </Typography>
              <Btn onClick={() => {
                setState((prev) => ({ ...prev, check: false }));
                handleModal()
              }}>
                <AddCircleIcon fontSize="small" sx={{ mx: 1 }} /> Add new
                Withdrawal
              </Btn>
            </Layout>
            <Stack sx={{ padding: "20px" }}>
              <DataTable
                editCashWithdrawal={handleModal}
                view={view}
                data={state.cashWithdrawalData}
                getData={getCashWithdrawals}
                handleCheck={handleCheck}
                clientData={state.clientData}
              />
            </Stack>
          </>
        ) : (
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Layout
              justifyContent="space-between"
              alignItems={"center"}
              direction={"row"}>
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "24px",
                  fontWeight: 700,
                  padding: 1,
                }}>
                {!smallScreen && "CashWithdrawal Details"}
              </Typography>
              <Stack direction={"row"}>
                {/* <Btn onClick={view}>
                  <AppReg fontSize="small" sx={{ mx: 1 }} /> View All CashWithdrawals
                </Btn> */}

                <ReactToPrint
                  trigger={() => (
                    <Btn>
                      <PrintIcon fontSize="small" sx={{ mx: 1 }} />{" "}
                      {!smallScreen && "Print"}
                    </Btn>
                  )}
                  content={() => componentRef.current}
                />

                <Btn onClick={view}>
                  <Back sx={{ mx: 1 }} />
                  {!smallScreen && "Back"}
                </Btn>
              </Stack>
            </Layout>
            <ComponentToPrint ref={componentRef} />
          </Stack>
        )}
      </>
      <CustomDialogue
        open={state.openModal}
        handleClose={handleModal}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <CashWithdrawalsIcon sx={{ color: "#008890" }} fontSize="large" />
            <Typo>Add new withdrawal</Typo>
          </Stack>
        }
        content={
          <Stack sx={{ padding: 2 }}>
            <CustomField
              fullWidth
              id="outlined-select-currency"
              select
              label="Bank Name"
              name="selectedBank"
              value={state.selectedBank}
              onChange={handleChange}
              sx={{ mb: 2 }}>
              {state.uniBanksArr.map((option) => (
                <MenuItem key={option} value={option}>
                  {`${option}`}
                </MenuItem>
              ))}
            </CustomField>
            <CustomField
              fullWidth
              id="outlined-select-currency"
              select
              label="Select Account"
              name="selectedAcc"
              value={state.selectedAcc}
              onChange={handleChange}
              sx={{ mb: 2 }}>
              {state.accountsArr.map((option, id) => (
                <MenuItem
                  key={option.account_number}
                  value={option.id}>
                  {`${option.account_title} [${option.account_number}]`}
                </MenuItem>
              ))}
            </CustomField>
            <CustomField
              fullWidth
              id="outlined-select-currency"
              label="Enter Amount"
              name="amount"
              value={state.amount}
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
            <Stack spacing={3} direction={"row"} sx={{ mt: 2 }}>
              <Button
                size="small"
                onClick={saveCashWithdrawal}
                sx={{
                  width: "250px",
                  textTransform: "none",
                  background: "#008890",
                  color: "#FFF",
                  "&:hover": {
                    background: "#61D140",
                  },
                }}>
                {state.check ? "update cashWithdrawal" : " save cashWithdrawal"}
              </Button>
              <Button
                onClick={handleModal}
                size="small"
                sx={{
                  background: "#FFF",
                  width: "250px",
                  textTransform: "none",

                  color: "#9F9E9E",
                  "&:hover": {
                    background: "#EFEFEF",
                  },
                }}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        }
      />
    </Stack>
  );
};

export default CashWithdrawal;
