import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Layout } from "../dashboard/dashboard";
import { Stack, Typography } from "@mui/material";
import { Btn } from "../payment";
import { Back } from "../../assets/Svg";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useDispatch } from "react-redux";
import { handleSelectedCom } from "../../sidebar/toggleSlice";
import { getProduct, getStock } from "../../apiservices";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactToPrint from "react-to-print";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#008890",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
  },
}));

export default function SimpleTable() {
  const componentRef = React.useRef();

  const [state, setState] = useState({
    productData: [],
  });
  const getProducts = async () => {
    let res = await getStock();
    if (res.success) {
      setState((prev) => ({ ...prev, productData: res.data }));
    }
  };
  React.useEffect(() => {
    getProducts();
  }, []);
  const dispatch = useDispatch();
  const handleSelection = (arg) => {
    dispatch(handleSelectedCom(arg));
  };
  const printToPDF = () => {
    // Select the content you want to print to PDF by its ID or class
    const contentToPrint = document.getElementById("content-to-print");

    // Use html2canvas to capture the content as an image
    html2canvas(contentToPrint).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Set dimensions for the PDF
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210; // A4 size
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add the captured image to the PDF
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

      // Download the PDF
      pdf.save("content.pdf");
    });
  };
  const ComponentToPrint = React.forwardRef((props, ref) => (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      ref={ref}
      id="content-to-print">
      <TableContainer
        component={Paper}
        sx={{ mt: 5, width: "90%" }}
        id="content-to-print">
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>No#</StyledTableCell>
              <StyledTableCell align="left">Model Name</StyledTableCell>
              <StyledTableCell align="left">Purchase Price</StyledTableCell>
              <StyledTableCell align="left">Sale Price</StyledTableCell>
              {/* <StyledTableCell align="left">Stock In</StyledTableCell>
              <StyledTableCell align="left">Stock Out</StyledTableCell> */}
              <StyledTableCell align="left">Stock</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.productData?.map((row, key) => (
              <StyledTableRow>
                <StyledTableCell align="left">{key + 1}</StyledTableCell>
                <StyledTableCell align="left">{row.description}</StyledTableCell>
                <StyledTableCell align="left">{row.purchase_price}</StyledTableCell>
                <StyledTableCell align="left">{row.sale_price}</StyledTableCell>
                {/* <StyledTableCell align="left">
                  {parseInt(row.opening_qty) + parseInt(row.stock_in)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {parseInt(row.stock_out)}
                </StyledTableCell> */}
                <StyledTableCell align="left">
                  {parseInt(row.opening_qty) + parseInt(row.stock_in) - parseInt(row.stock_out)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  ));
  return (
    <>
      <Layout
        justifyContent="space-between"
        alignItems={"center"}
        direction={"row"}>
        <Typography></Typography>
        <Typography
          sx={{
            color: "#FFF",
            fontSize: "24px",
            fontWeight: 700,
            padding: 1,
          }}>
          Current Stock
        </Typography>
        <Stack direction={"row"}>
          {/* <ReactToPrint
            trigger={() => (
              <Btn>
                <PictureAsPdfIcon fontSize="small" sx={{ mx: 1 }} /> PDF
              </Btn>
            )}
            content={() => componentRef.current}
          /> */}

          <ReactToPrint
            trigger={() => (
              <Btn>
                <PrintIcon fontSize="small" sx={{ mx: 1 }} /> Print
              </Btn>
            )}
            content={() => componentRef.current}
          />
          <Btn onClick={() => handleSelection("dashboard")}>
            <Back sx={{ mx: 1 }} /> Back
          </Btn>
        </Stack>
      </Layout>
      <ComponentToPrint ref={componentRef} />
    </>
  );
}
