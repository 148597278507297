import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Stack, styled, Typography } from "@mui/material";
import { deleteBank, loginAdmin } from "../../apiservices";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { CustomToolbar } from "../categories/categoryTable";
import CustomDialogue from "../reuseableComp/Modal";
import { Typo } from "../payment";

const Btn = styled(Button)({
  textTransform: "none",
  background: "#EEF4FF",
  color: "#008890",
  "&:hover": {
    color: "#FFF",
    background: "#008890",
  },
});

export default function DataTable({
  editBankDetails,
  bankList,
  handleCheck,
  getData,
  viewBank,
}) {
  const [state, setState] = React.useState({
    password: "",
    isDelete: false
  });
  const columns = [
    { field: "bank_name", headerName: "Bank Name", width: 200 },
    { field: "account_title", headerName: "Account Title", width: 300 },
    { field: "account_number", headerName: "Account No#", width: 200 },
    { field: "balance", headerName: "Balance", width: 200, valueFormatter: (params) => `Rs ${params.value}` },
    {
      field: "action",
      headerName: "Actions",
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <strong>
         <Btn
            sx={{ height: '30px', width: '100px', mr: '5px', padding: 0 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => viewBank(params.row)}>
            View Ledger
          </Btn>
          <Btn
            title="Edit"
            variant="contained"
            sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "#eed202", color: "white" }}
            onClick={() => edit(params.row)}>
            <EditIcon fontSize="small" />
          </Btn>
          <Btn
            title="Delete"
            sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "red", color: "white" }}
            variant="contained"
            onClick={() => deleteRow(params.row)}>
            <DeleteIcon fontSize="small" />
          </Btn>
          {/* You can add more buttons or customize based on your requirements */}
        </strong>
      ),
    },
  ];
  const rows = bankList;
  const edit = (params) => {
    editBankDetails(params);
    handleCheck();
  };
  const deleteRow = async (params) => {
    setState((prev) => ({
      ...prev,
      isDelete: true,
      id: params.id,
      bank_name: params.bank_name,
    }));
  };
  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      <CustomDialogue
      open={state.isDelete}
      handleClose={() => { }}
      header={
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Typo>Deletion</Typo>
        </Stack>
      }
      content={
        <>
          <Stack sx={{ padding: 1 }}>
            <Stack sx={{ mt: 2 }}>
              <Typography>Please enter password if you really want to delete <strong>{`"${state.bank_name}"`}</strong> bank account.</Typography>
              <input onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  password: e.target.value,
                }));
              }} type="password" />
            </Stack>
          </Stack>
          <div style={{ float: "right" }}>
            <Btn
              sx={{ height: '30px', mt: '10px', mr: '5px', padding: 0, background: "red", color: "white" }}
              variant="contained"
              size="small"
              onClick={async () => {
                let userData = JSON.parse(localStorage.getItem("user"));
                let credentials = {
                  username: userData.username,
                  password: state.password,
                };
                let resUser = await loginAdmin(credentials);
                if (resUser.success) {
                  let res = await deleteBank(state.id);
                  if (res.success) {
                    toast.success("Bank deleted successfully");
                    getData();
                    setState((prev) => ({
                      ...prev,
                      isDelete: false,
                    }));
                  }
                }
                else {
                  toast.error("Wrong password...!")
                }
              }}
            >Delete</Btn>
            <Btn
              sx={{ height: '30px', mt: '10px', mr: '5px', padding: 0 }}
              variant="contained"
              color="error"
              size="small"
              onClick={() => {
                setState((prev) => ({
                  ...prev,
                  isDelete: false,
                }));
              }}
            >Close</Btn>
          </div>
        </>
      }
    />
    </div>
  );
}
