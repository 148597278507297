import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#008890",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function SaleTable({ data }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <TableContainer
      component={Paper}
      sx={{ minWidth: fullScreen ? "300px" : "800px" }}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Date </StyledTableCell>

            <StyledTableCell align="center">Invoice #</StyledTableCell>
            <StyledTableCell align="center">Client</StyledTableCell>
            <StyledTableCell align="center">Profit</StyledTableCell>
            <StyledTableCell align="center">Amount</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <StyledTableRow key={1}>
              <StyledTableCell align="center">
                {moment(row.date).format("DD-MM-YYYY")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row && (row.voucher_no.length == 1 ? "Invoice#00" : row.voucher_no.length == 2 ? "Invoice#0" : "Invoice#")}
                {`${row.voucher_no}`}
              </StyledTableCell>
              <StyledTableCell align="center">{row.client_name}</StyledTableCell>
              <StyledTableCell align="center">{row.profit}</StyledTableCell>
              <StyledTableCell align="center">{row.amount}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
