import {
  Box,
  Button,
  MenuItem,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../dashboard/dashboard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataTable from "./SupplierTable";
import CustomDialogue from "../reuseableComp/Modal";
import { Typo } from "../payment";
import { CustomField, Lay } from "../client";
import { PersonLogo } from "../../assets/Svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  getBillsOfClient,
  getCountry,
  getData,
  getPurchasesOfSuppliers,
  getSupplier,
  getSupplierLedgers,
  saveSupplier,
  updateSupplier,
} from "../../apiservices";
import { toast } from "react-toastify";
import Search from "../searchBar";
import { formatDate } from "../general";
import { Back, ClientLogo } from "../../assets/Svg";
import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import SimpleTable from "./SimpleTable";

const Btn = styled(Button)({
  minWidth: "126px",
  height: "36px",
  padding: "4px",
  borderRadius: "4px",
  textTransform: "none",
  background: "#FFFFFF",
  color: "#008890",
  marginRight: 10,
  "&:hover": {
    background: "#F6F6F6",
  },
  "&:active": {
    background: "#FFFFFF",
  },
});
export const schema = yup
  .object({
    fName: yup.string().required(),
    address: yup.string().required(),
    num: yup.string().required(),
    opening_balance: yup.string().required(),
  })
  .required();

const Supplier = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const componentRef = useRef();
  const [state, setState] = useState({
    openModal: false,
    providersData: [],
    ledgerData: {},
    check: false,
    updatedData: [],
    searchData: [],
    cityArr: [],
    city: "",
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const handleModal = (data = "") => {
    setState((prev) => ({
      ...prev,
      openModal: !prev.openModal,
      check: false,
      updatedData: data,
    }));
    if (data) {
      setValue("fName", data.name);
      setValue("cName", data.company);
      setState((prev) => ({ ...prev, city: data.country_id }));

      setValue("address", data.address);
      setValue("num", data.contact);
      setValue("opening_balance", data.opening_balance);
    }
  };
  const getSuppliers = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    let obj = {};
    let res = await getSupplierLedgers();
    if (res.success) {
      const data = res.data.map(x => {
        return ({
          ...x,
          balance: (parseInt(x.opening_balance) + parseInt(x.debit) - parseInt(x.credit))
        })
      })
      setState((prev) => ({
        ...prev,
        providersData: data
      }));
    }
    else{
      setState((prev) => ({
        ...prev,
        providersData: [],
      }));
    }
  };
  const getCount = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    let arr = [];
    let obj = {};
    let res = await getCountry(obj);
    if (res.success) {
      // res.data.map((item) => arr.push(item.city_name));
      setState((prev) => ({ ...prev, cityArr: res.data }));
    }
  };
  useEffect(() => {
    getSuppliers();
    getCount();
  }, []);

  const viewLedger = async (data = "") => {
    // console.log(data)
    if (data.id) {
      const dPDataObj = {
        table: "supplier_payments",
        filters: [
          {
            column_name: "supplier_id",
            operation: "=",
            value: data.id
          },
          {
            column_name: "entry_type",
            operation: "=",
            value: 'Debit'
          }
        ],
      }
      const debitPaymentRes = await getData(dPDataObj)
      // console.log("paymentRes", paymentRes)

      const cPDataObj = {
        table: "supplier_payments",
        filters: [
          {
            column_name: "supplier_id",
            operation: "=",
            value: data.id
          },
          {
            column_name: "entry_type",
            operation: "=",
            value: 'Credit'
          }
        ],
      }
      const creditPaymentRes = await getData(cPDataObj)
      // console.log("paymentRes", paymentRes)

      const purchaseRes = await getPurchasesOfSuppliers(data.id)
      // console.log("purchaseRes", purchaseRes)

      let balance = []
      balance.push({
        date: "",
        details: `Opening Balance`,
        debit: 0,
        credit: 0,
        closing: data.opening_balance
      })
      for (var d = new Date('2024-03-01'); d <= new Date(); d.setDate(d.getDate() + 1)) {
        if (purchaseRes && purchaseRes.data && purchaseRes.data.length) {
          purchaseRes.data.map((element) => {
            if (formatDate(element.date) == formatDate(d)) {
              balance.push({
                date: element.date,
                details: element && (element.voucher_no.toString().length == 1 ? "Purchase#00" : element.voucher_no.toString().length == 2 ? "Purchase#0" : "Purchase#") + element.voucher_no,
                debit: 0,
                credit: element.amount
              })
            }
          })
        }
        if (debitPaymentRes && debitPaymentRes.data && debitPaymentRes.data.length) {
          debitPaymentRes.data.map((element) => {
            if (formatDate(element.date) == formatDate(d)) {
              balance.push({
                date: element.date,
                details: element && (element.remarks ? element.remarks : (element.id.toString().length == 1 ? "Payment #00" : element.id.toString().length == 2 ? "Payment#0" : "Payment#") + element.id),
                debit: element.amount,
                credit: 0
              })
            }
          })
        }
        if (creditPaymentRes && creditPaymentRes.data && creditPaymentRes.data.length) {
          creditPaymentRes.data.map((element) => {
            if (formatDate(element.date) == formatDate(d)) {
              balance.push({
                date: element.date,
                details: element && (element.remarks ? element.remarks : (element.id.toString().length == 1 ? "Payment #00" : element.id.toString().length == 2 ? "Payment#0" : "Payment#") + element.id),
                debit: 0,
                credit: element.amount
              })
            }
          })
        }
      }
      if (balance && balance.length) {
        for (let i = 0; i < balance.length; i++) {
          if (i == 0) {
            balance[i].closing = parseFloat(data.opening_balance) + parseFloat(balance[i].debit) - parseFloat(balance[i].credit)
          }
          else {
            balance[i].closing = parseFloat(balance[i - 1].closing) + parseFloat(balance[i].debit) - parseFloat(balance[i].credit)
          }
        }
      }

      // console.log("balance", balance)

      setState((prev) => ({
        ...prev,
        ledgerData: balance,
        supplierData: data
      }));
    }
    setState((prev) => ({
      ...prev,
      viewLedger: !prev.viewLedger,
    }));
  };

  const ComponentToPrint = React.forwardRef((props, ref) => (
    <Stack id="content-to-print" ref={ref} sx={{ padding: 3 }}>
      <Stack sx={{ mb: 3 }}>
        <Lay
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            paddingLeft: 2,
            paddingRight: 2,
            mt: 5,
            borderTop: "1px solid #9F9E9E",
          }}>
          <Typography>Supplier Name:</Typography>
          <Typography>{state.supplierData.name}</Typography>
        </Lay>
        <Lay
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ paddingLeft: 2, paddingRight: 2 }}>
          <Typography>Contact Number:</Typography>
          <Typography>{state.supplierData.contact}</Typography>
        </Lay>
        <Lay
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ paddingLeft: 2, paddingRight: 2 }}>
          <Typography>City:</Typography>
          <Typography>{state.supplierData.city_name}</Typography>
        </Lay>

        <Lay
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ paddingLeft: 2, paddingRight: 2 }}>
          <Typography>Current Balance:</Typography>
          <Typography>{parseFloat(state.supplierData.opening_balance) + parseFloat(state.supplierData.debit) - parseFloat(state.supplierData.credit)}</Typography>
        </Lay>
      </Stack>
      <SimpleTable
        data={state.supplierDataById?.clientArray}
        supplierData={state.ledgerData}
        ledgerData={state.ledgerData}
      />
      <Lay
        direction={fullScreen ? "column" : "row"}
        alignItems={"center"}
        justifyContent={"space-around"}
        sx={{ paddingRight: 2, mt: fullScreen ? 4 : 1 }}>
        <Stack direction={"row"} sx={{ mx: 2 }}>
          <Typography>Total Debit:</Typography>
          <Typography>{` Rs ${state.supplierData.debit}`}</Typography>
        </Stack>
        <Stack direction={"row"} sx={{ mx: 2 }}>
          <Typography>Total Credit:</Typography>
          <Typography>{` Rs ${state.supplierData.credit}`}</Typography>
        </Stack>
        <Stack direction={"row"} sx={{ mx: 2 }}>
          <Typography>Balance:</Typography>
          <Typography> Rs {parseFloat(state.supplierData.opening_balance) + parseFloat(state.supplierData.debit) - parseFloat(state.supplierData.credit)}</Typography>
        </Stack>
      </Lay>
    </Stack>
  ));

  const onSubmit = async (data) => {
    console.log("data", data)
    const userData = JSON.parse(localStorage.getItem("user"));

    if (state.check) {
      let obj = {
        name: data.fName,
        country_id: state.city,
        contact: data.num,
        address: data.address,
        opening_balance: data.opening_balance,
        updated_by: userData.id
      };
      let res = await updateSupplier(obj, state.updatedData.id);
      if (res) {
        toast.success("Supplier updated successfully");
        getSuppliers();
        handleModal();
      }
    } else {
      let obj = {
        name: data.fName,
        country_id: state.city,
        contact: data.num,
        address: data.address,
        opening_balance: data.opening_balance,
        added_by: userData.id
      };
      console.log(obj)
      let res = await saveSupplier(obj);
      if (res) {
        toast.success("Supplier saved successfully");
        getSuppliers();
        handleModal();
      }
    }
  };
  const handleCheck = () => {
    setState((prev) => ({ ...prev, check: !prev.check }));
  };
  const updateData = (data) => {
    setState((prev) => ({ ...prev, providersData: data }));
  };
  const handleChange = (e) => {
    let { name } = e.target;
    let { value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <Stack sx={{ paddingLeft: 2, paddingRight: 2 }}>
      {state.viewLedger ? (
        <Stack>
          <Layout justifyContent="end" alignItems={"center"} direction={"row"}>
           
            {/* <ReactToPrint
              trigger={() => (
                <Btn>
                  <PictureAsPdfIcon fontSize="small" sx={{ mx: 1 }} />
                  {!fullScreen && "PDF"}
                </Btn>
              )}
              content={() => componentRef.current}
            /> */}

            <ReactToPrint
              trigger={() => (
                <Btn>
                  <PrintIcon fontSize="small" sx={{ mx: 1 }} />
                  {!fullScreen && "Print"}
                </Btn>
              )}
              content={() => componentRef.current}
            />
             <Btn onClick={viewLedger}>
              <Back sx={{ mx: 1 }} />
              {!fullScreen && " Back"}
            </Btn>
          </Layout>
          <ComponentToPrint ref={componentRef} />
        </Stack>
      ) : 
      <>
      <Layout
          justifyContent="space-between"
          alignItems={"center"}
          direction={"row"}>
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "24px",
              fontWeight: 700,
            }}></Typography>
          <Typography sx={{ color: "#FFF", fontSize: "24px", fontWeight: 700 }}>
            Suppliers
          </Typography>
          <Btn onClick={handleModal}>
            <AddCircleIcon fontSize="small" sx={{ mx: 1 }} /> Add new supplier
          </Btn>
        </Layout>
        <Stack sx={{ padding: "20px" }}>
          <DataTable
            editSupplier={handleModal}
            data={state.providersData}
            handleCheck={handleCheck}
            getData={getSuppliers}
            viewLedger={(data) => viewLedger(data)}
          />
        </Stack>
        </>
      }
      <CustomDialogue
        open={state.openModal}
        handleClose={handleModal}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Box sx={{ color: "#008890" }}>
              <PersonLogo />
            </Box>
            <Typo>Add new Supplier</Typo>
          </Stack>
        }
        content={
          <Stack sx={{ padding: 1 }}>
            <Stack gap={2} sx={{ mb: 2 }}>
              <Stack>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  label="Supplier Name"
                  {...register("fName")}
                  sx={{
                    m: 0,
                    background: "#F6F6F6",
                    "& label.Mui-focused": {
                      color: "#008890",
                    },
                  }}
                />
                {errors.fName && (
                  <p style={{ color: "red" }}>{errors.fName?.message}</p>
                )}
              </Stack>
            </Stack>
            <Stack gap={2} sx={{ mb: 2 }}>
              <Stack>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  label="Mobile Number"
                  {...register("num")}
                  sx={{
                    background: "#F6F6F6",
                    "& label.Mui-focused": {
                      color: "#008890",
                    },
                  }}
                />
                {errors.num && (
                  <p style={{ color: "red" }}>{errors.num?.message}</p>
                )}
              </Stack>
            </Stack>
            <Stack gap={2} sx={{ mb: 3 }}>
              <Stack>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  label="City"
                  select
                  name="city"
                  value={state.city}
                  onChange={handleChange}
                  sx={{
                    background: "#F6F6F6",
                    "& label.Mui-focused": {
                      color: "#008890",
                    },
                  }}>
                  {state.cityArr.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.city_name}
                  </MenuItem>
                ))}
                </CustomField>
              </Stack>
              <Stack>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  label="Address"
                  {...register("address")}
                  sx={{
                    background: "#F6F6F6",
                    "& label.Mui-focused": {
                      color: "#008890",
                    },
                  }}
                />
                {errors.address && (
                  <p style={{ color: "red" }}>{errors.address?.message}</p>
                )}
              </Stack>
              <Stack>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  label="Opening Balance"
                  {...register("opening_balance")}
                  sx={{
                    background: "#F6F6F6",
                    "& label.Mui-focused": {
                      color: "#008890",
                    },
                  }}
                />
                {errors.opening_balance && (
                  <p style={{ color: "red" }}>{errors.opening_balance?.message}</p>
                )}
              </Stack>
            </Stack>

            <Stack spacing={3} direction={"row"}>
              <Button
                onClick={handleModal}
                size="small"
                sx={{
                  background: "#FFF",
                  width: "200px",
                  textTransform: "none",

                  color: "#9F9E9E",
                  "&:hover": {
                    background: "#EFEFEF",
                  },
                }}>
                Cancel
              </Button>
              <Button
                onClick={handleSubmit(onSubmit)}
                size="small"
                sx={{
                  width: "200px",
                  textTransform: "none",
                  background: "#008890",
                  color: "#FFF",
                  "&:hover": {
                    background: "#61D140",
                  },
                }}>
                {state.check ? "update supplier" : " Save supplier"}
              </Button>
            </Stack>
          </Stack>
        }
      />
    </Stack>
  );
};

export default Supplier;
