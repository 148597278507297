import {
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
  Box,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CustomField } from "../client/index.jsx";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";
import {
  getClient,
  getCustomerLedgers,
  getInvoice,
  getProduct,
  getStock,
  saveSale,
  updateBillZkTraders,
} from "../../apiservices/index.jsx";
import moment from "moment/moment.js";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { handleSalesData } from "./saleSlice.jsx";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#008890",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const Layout = styled(Stack)({
  width: "380px",
  borderRadius: "8px",
  border: "0px 0px 1px 0px",
  background: "#008890",
  padding: "10px",
  marginBottom: 30,
});
const Typo = styled(Typography)({
  color: "#323232",
  fontWeight: 600,
  // width: "200px",
  borderBottom: "1px",
});

export const CustomBtn = styled(Button)({
  textTransform: "none",
  width: "90px",
});
function AddSale({
  getTabelData,
  itemsArr,
  cancelSale,
  customData,
  rowData,
  getBillData,
}) {
  const bottomRef = useRef(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [newItems, setNewItems] = useState([]);
  const [state, setState] = useState({
    search: "",
    searchModal: false,
    searchArr: [],
    modelSearch: "",
    modelName: "",
    sumQty: 0,
    selectedModel: "",
    addItemsArr: itemsArr ? itemsArr : [],
    newAddItemArr: [],
    qty: 0,
    subTotal: customData.subTotal ? customData.subTotal : 0,
    discount: 0,
    disType: "price",
    total: customData.prev ? customData.prev : 0,
    paid: customData.paid ? customData.paid : 0,
    balance: 0,
    cName: customData.client_name ? customData.client_name : "",
    prevBalance: 0,
    clientDataForAutoComplete: [],
    clientData: [],
    setInvoiceCheck: false,
    selectedClient: customData.customer_id ? {
      clientName: customData.client_name,
      clientId: customData.customer_id
    } : {},
    clientNo: "",
    clientAdd: "",
    productData: [],
    productDataForAutoComplete: [],
    addQty: 0,
  });
  // const inputRef = useRef(null);
  let addArr = state.addItemsArr;
  const getClientsData = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    let obj = {}
    let res = await getCustomerLedgers(obj);
    const arr = []
    if (res.success) {
      res.data.forEach((item) => {
        arr.push({
          clientName: `${item.client_name} (Balance: ${parseFloat(item.opening_balance) + parseFloat(item.debit) - parseFloat(item.credit)})`,
          clientId: item.id
        })
      });

      setState((prev) => ({ ...prev, clientData: res.data, clientDataForAutoComplete: arr }));
    }
  };
  useEffect(() => {
    getClientsData();
    if (bottomRef.current) {
      bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    setInvoice()
  }, [state.productData]);

  const setInvoice = async () => {
    if (customData.id && !state.setInvoiceCheck) {
      const ledgerRes = await getCustomerLedgers()
      let balance = 0
      if (ledgerRes.success) {
        const filtered = ledgerRes.data.filter(x => x.id == customData.customer_id)
        balance = (parseInt(filtered[0].opening_balance) + parseInt(filtered[0].debit) - parseInt(filtered[0].credit))
      }
      const res = await getInvoice(customData.voucher_no)
      if (res.success) {
        const arr = []
        if (state.productData && state.productData.length > 0) {
          let subTotal = 0
          res.data.map((data) => {
            const p = state.productData.filter(x => x.id == data.product_id)[0]
            arr.push({
              id: data.id,
              modelId: data.product_id,
              modelName: p.description,
              currentStock: (parseInt(p.opening_qty) + parseInt(p.stock_in) - parseInt(p.stock_out)),
              qty: data.qty,
              costPrice: p.purchase_price,
              price: data.price,
              subTotal: (data.price * data.qty),
              items: 0
            })
            subTotal += (data.price * data.qty)
          })
          setState((prev) => ({ ...prev, addItemsArr: arr, setInvoiceCheck: true, prevBalance: (balance - subTotal), subTotal: subTotal, balance: balance }));
        }
      }
    }
  }

  const handleChange = (e, id = "") => {
    // let subTotal = 0;
    // let total = Number(state.prevBalance);
    let { value } = e.target;
    let { name } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
    if (name === "disType") {
      setState((prev) => ({ ...prev, discount: "", total: "" }));
    } 
    else if (name === "selectedClient") {
      let filtered = state.clientData.filter(
        (item) => item.clientName === value
      );
      setState((prev) => ({
        ...prev,
        cName: value,
        selectedClient: value,
        clientAdd: value.address,
        clientNo: value.mobileNumber
      }));
    }
  };
  const makeBill = async () => {
    if (!state.selectedClient.clientId) {
      toast.error("Client must be selected...!")
      return
    }
    let userData = JSON.parse(localStorage.getItem("user"));
    if (state.subTotal) {
      let filtered = state.clientData.filter(
        (item) => item.clientName === customData.clientName
      );
      if (customData?.id) {
        const saleObj = [...state.addItemsArr?.map((item) => ({
          product_id: item.modelId,
          qty: item.qty,
          price: item.price,
          purchase_price: item.costPrice,
        })), ...newItems?.map((item) => ({
          product_id: item.modelId,
          qty: item.qty,
          price: item.price,
          purchase_price: item.costPrice,
        }))];

        // console.log("saleObj", saleObj)

        const obj = {
          voucher_no: customData.voucher_no,
          date: new Date().toISOString().split("T")[0],
          customer_id: state.selectedClient.clientId,
          added_by: userData.id,
          updated_by: userData.id,
          sub_values: saleObj
        }

        let res = await updateBillZkTraders(obj);
        if (res.success) {
          toast.success("Sale updated successfully...!")
          cancelSale();
          getBillData();
        }
      } else {
        const saleObj = newItems.map((item) => ({
          product_id: item.modelId,
          qty: item.qty,
          price: item.price,
          purchase_price: item.costPrice,
        }));

        const obj = {
          date: new Date().toISOString().split("T")[0],
          customer_id: state.selectedClient.clientId,
          added_by: userData.id,
          sub_values: saleObj
        }

        const res = await saveSale(obj);
        if (res.success) {
          toast.success("Sale added successfully...!")
          cancelSale();
          getBillData();
        }
      }
    } else {
      toast.error("please enter items ");
    }
  };
  const handleNewFeature = (e, id) => {
    let totalQty = 0;
    let subTotal = 0;
    let { value, name } = e.target;
    let array = [...state.addItemsArr, ...newItems];
    array.map((element, index) => {
      if (index === id) {
        element[name] = value;
        element["salePrice"] = name === "price" ? value : element.price;
        element["subTotal"] = Number(element.price) * Number(element.qty);
      }
    });
    array.forEach((element) => {
      subTotal += element.subTotal;
      totalQty += parseInt(element.qty);
    });
    setState((prev) => ({
      ...prev,
      subTotal: subTotal,
      sumQty: totalQty,
      balance: subTotal + Number(prev.prevBalance),
      total: subTotal + Number(prev.prevBalance),
    }));
    // setState((prev) => ({ ...prev, addItemsArr: arr }));
  };
  const getProducts = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    let obj = {};
    let res = await getStock(obj);
    const arr = []
    if (res.success) {
      res.data.forEach((item) => {
        arr.push({
          modelName: item.description,
          modelId: item.id
        })
      });
      setState((prev) => ({
        ...prev,
        productData: res.data,
        productDataForAutoComplete: arr,
      }));
    }
  };
  useEffect(() => {
    getProducts();
  }, [state.subTotal]);

  const deleteRow = async (index) => {
    let totalQty = 0;
    let subTotal = 0;
    
    let arr = [...state.addItemsArr, ...newItems];
    arr.splice(index, 1);
    arr.forEach((element) => {
      totalQty += parseInt(element.qty);
      subTotal += element.subTotal;
    });

    setState((prev) => ({
      ...prev,
      subTotal: subTotal,
      sumQty: totalQty,
      balance: subTotal + Number(prev.prevBalance),
      total: subTotal + Number(prev.prevBalance),
    }));
    setNewItems(arr);
    setState((prev) => ({ ...prev, addItemsArr: [] }));
  };
  
  const handleNewOption = (event, newOption, id) => {
    let totalQty = 0;
    let subTotal = 0;
    let total = Number(state.prevBalance);
    let filtered = state.productData.filter(
      (item) => item?.description === newOption?.modelName
    );
    let array = state.addItemsArr;
    [...array, ...newItems].map((item, index) => {
      if (filtered && filtered.length > 0) {
        if (index == id) {
          item["modelId"] = filtered[0].id;
          item["modelName"] = filtered[0].description;
          item["id"] = filtered[0].id;
          item["items"] = 0;
          item["currentStock"] = (parseInt(filtered[0].opening_qty) + parseInt(filtered[0].stock_in) - parseFloat(filtered[0].stock_out));
          item["price"] = filtered[0].sale_price;
          item["costPrice"] = filtered[0].purchase_price;
          item["salePrice"] = filtered[0].sale_price;
          item["qty"] = 0;
          item["subTotal"] = 0;
        }
      }
      subTotal += item.qty * item.price;
      totalQty += parseInt(item.qty);
    });

    setState((prev) => ({
      ...prev,
      addItemsArr: array,
      subTotal: subTotal,
      sumQty: totalQty,
      balance: subTotal + total,
      total: subTotal + total,
      // newAddItemArr: arr,
    }));
    document.getElementById(`qty_${id}`).focus()
  };
  
  const handleIChange = (event, newInputValue, id) => {
    if (newInputValue != "undefined") {
      if (newInputValue) {
        setState((prev) => ({ ...prev, selectedModel: newInputValue }));
      } else {
        setState((prev) => ({ ...prev, selectedModel: "" }));
      }
    }
  };
  const removeFocus = () => {
    // if (inputRef.current) {
    //   inputRef.current.focus();
    // }
  };
  const handleModelChange = (event, newOption) => {
    let totalQty = 0;
    let subTotal = 0;
    let total = Number(state.prevBalance);
    // if (newOption) {
    //   setState((prev) => ({
    //     ...prev,
    //     modelName: newOption.modelName,
    //     modelSearch: newOption.modelName,
    //   }));
    // }
    // else {
    //   setState((prev) => ({
    //     ...prev,
    //     modelName: "",
    //     modelSearch: "",
    //   }));
    // }
    let filtered = state.productData.filter(
      (item) => item?.description === newOption?.modelName
    );
    // console.log(filtered)
    let array = [...newItems, ...state.addItemsArr];
    if (filtered && filtered.length > 0) {
      let obj = {
        date: new Date(),
        // items: filtered[0].opening_qty,
        modelId: filtered[0].id,
        modelName: filtered[0].description,
        currentStock: (parseInt(filtered[0].opening_qty) + parseInt(filtered[0].stock_in) - parseFloat(filtered[0].stock_out)),
        qty: 0,
        price: filtered[0].sale_price,
        costPrice: filtered[0].purchase_price,
        returnQty: 0,
        subTotal: 0,
        id: filtered[0].id,
      };

      // array.push(obj);
      setNewItems((prev) => [...prev, obj]);
      // getTabelData(array);
    }
    array.map((item) => {
      totalQty += parseInt(item.qty);
      subTotal += item.qty * item.price;
    });
    setState((prev) => ({
      ...prev,
      subTotal: subTotal,
      sumQty: totalQty,
      balance: subTotal + total,
      total: subTotal + total,
      modelName: "",
      selectedModel: "",
    }));
    setTimeout(() => {
      if(document.getElementById(`qty_${state.addItemsArr.length + newItems.length}`)){
        document.getElementById(`qty_${state.addItemsArr.length + newItems.length}`).focus()
      }
    })
  };

  const handleClientChange = (e, value) => {
    // removeFocus();

    if (value) {
      setState((prev) => ({
        ...prev,
        selectedClient: value,
      }));
      let filtered = state.clientData.filter(
        (item) => item.id === value.clientId
      );
      setState((prev) => ({
        ...prev,
        cName: value,
        selectedClient: value,
        clientAdd: value.address,
        clientNo: value.mobileNumber,
        prevBalance: filtered && filtered.length ? (parseInt(filtered[0].opening_balance) + parseInt(filtered[0].debit) - parseInt(filtered[0].credit)) : 0,
        total: filtered && filtered.length ? Number(filtered[0].opening_balance) + Number(prev.subTotal) : 0,
        balance: filtered && filtered.length ? Number(filtered[0].opening_balance) + Number(prev.subTotal) : 0,
      }));
    }
  };
  return (
    <>
      <Grid container sx={{ padding: 1 }}>
        <Grid item spacing={2} sx={{ mx: 1, mb: 2 }}>
          <Typo>Select Client</Typo>
          <Layout>
            <Autocomplete
              size="small"
              options={state.clientDataForAutoComplete}
              fullWidth
              value={state.selectedClient}
              onChange={(e, value) => handleClientChange(e, value)}
              getOptionLabel={(option) =>
                option?.clientName || option.client || ""
              }
              sx={{ mb: 2 }}
              renderInput={(params) => (
                <CustomField
                  {...params}
                  label="Select or Type client name"
                  variant="outlined"
                // InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Layout>
          <Box sx={{ maxHeight: "350px", overflowY: "auto" }}>
            <TableContainer>
              <Table sx={{ width: "100%" }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>SR#</StyledTableCell>
                    <StyledTableCell align="left">Item Name</StyledTableCell>
                    <StyledTableCell align="left">
                      Current Quantity{" "}
                    </StyledTableCell>
                    <StyledTableCell align="left">Quantity</StyledTableCell>
                    <StyledTableCell align="left">Price</StyledTableCell>
                    <StyledTableCell align="left">Sub Total</StyledTableCell>
                    <StyledTableCell align="left">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {state.addItemsArr.map((item, id) => (
                    <TableRow key={1}>
                      <StyledTableCell>
                        <Typography>{id + 1}</Typography>
                      </StyledTableCell>
                      <StyledTableCell sx={{ width: "30%" }}>
                        <Autocomplete
                          size="small"
                          options={state.productDataForAutoComplete}
                          fullWidth
                          value={item.modelName}
                          // inputValue={state.modelSearch}
                          onChange={(e, value) =>
                            handleNewOption(e, value, id)
                          }
                          // onInputChange={(e, value) =>
                          //   handleInputChange(e, value, item.id)
                          // }
                          getOptionLabel={(option) =>
                            option?.modelName ? option?.modelName : option
                          }
                          renderInput={(params) => (
                            <CustomField
                              {...params}
                              label="Select or Type product"
                              variant="outlined"
                              inputRef={inputRef}
                            // InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          type="number"
                          disabled
                          name="qty"
                          value={item.currentStock}
                          sx={{
                            background: "#FFF",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          type="number"
                          name="qty"
                          value={item.qty}
                          onChange={(e) => handleFeaturesChange(e, item.id)}
                          label="Add Quantity"
                          sx={{
                            background: "#FFF",
                          }}
                        />
                      </StyledTableCell>

                      <StyledTableCell>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          label="price"
                          type="number"
                          value={item.price}
                          name="price"
                          onChange={(e) => handleFeaturesChange(e, item.id)}
                          sx={{
                            background: "#FFF",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell sx={{ width: "15%" }}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          type="number"
                          disabled
                          name="qty"
                          value={item.subTotal}
                          sx={{
                            background: "#FFF",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <span
                          style={{ color: "red" }}
                          onClick={() => deleteRow(item.id)}>
                          <DeleteIcon />
                        </span>
                      </StyledTableCell>
                    </TableRow>
                  ))} */}
                  {[...state.addItemsArr, ...newItems].map((item, id) => (
                    <TableRow key={1}>
                      {/* {console.log(item)} */}
                      <StyledTableCell>
                        <Typography>
                          {state.addItemsArr.length + id + 1}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell sx={{ width: "30%" }}>
                        <Autocomplete
                          size="small"
                          options={state.productDataForAutoComplete}
                          fullWidth
                          value={item.modelName}
                          // inputValue={state.modelSearch}
                          onChange={(e, value) => {

                            handleNewOption(e, value, id)
                          }}
                          // onInputChange={(e, value) =>
                          //   handleNewInput(e, value, item.id)
                          // }
                          getOptionLabel={(option) =>
                            option?.modelName ? option?.modelName : option
                          }
                          renderInput={(params) => (
                            <CustomField
                              {...params}
                              label="Select or Type product"
                              variant="outlined"
                              // inputRef={inputRef}
                            // InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          type="number"
                          disabled
                          name="currentStock"
                          value={item.currentStock}
                          sx={{
                            background: "#FFF",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          id={`qty_${id}`}
                          variant="outlined"
                          size="small"
                          type="number"
                          name="qty"
                          value={item.qty}
                          onChange={(e) => handleNewFeature(e, id)}
                          label="Add Quantity"
                          sx={{
                            background: "#FFF",
                          }}
                        />
                      </StyledTableCell>

                      <StyledTableCell>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          label="price"
                          type="number"
                          value={item.price}
                          name="price"
                          onChange={(e) => handleNewFeature(e, id)}
                          sx={{
                            background: "#FFF",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell sx={{ width: "15%" }}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          type="number"
                          disabled
                          name="qty"
                          value={item.subTotal}
                          sx={{
                            background: "#FFF",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <span
                          style={{ color: "red" }}
                          onClick={() => deleteRow(id)}>
                          <DeleteIcon />
                        </span>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                  <TableRow key={1}>
                    <StyledTableCell>
                      <Typography>
                        {state.addItemsArr.length + newItems.length + 1}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "30%" }}><Autocomplete
                        size="small"
                        options={state.productDataForAutoComplete && state.productDataForAutoComplete.length > 0 && state.productDataForAutoComplete}
                        fullWidth
                        value={state.modelName}
                        inputValue={state.selectedModel}
                        onChange={(e, value) => handleModelChange(e, value)}
                        onInputChange={(e, value) => handleIChange(e, value)}
                        getOptionLabel={(option) =>
                          option?.modelName ? option?.modelName : option
                        }
                        renderInput={(params) => (
                          <CustomField
                            {...params}
                            label="Select or Type product"
                            variant="outlined"
                          // InputLabelProps={{ shrink: true }}
                          />
                        )}
                        disabled={!state.productDataForAutoComplete || state.productDataForAutoComplete.length == 0}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        type="number"
                        disabled
                        name="currentStock"
                        sx={{
                          background: "#FFF",
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        id={`qty_${state.addItemsArr.length + newItems.length}`}
                        variant="outlined"
                        size="small"
                        type="number"
                        disabled
                        name="qty"
                        label="Add Quantity"
                        sx={{
                          background: "#FFF",
                        }}
                      />
                    </StyledTableCell>

                    <StyledTableCell>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        label="price"
                        type="number"
                        name="price"
                        disabledn
                        sx={{
                          background: "#FFF",
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        type="number"
                        disabled
                        name="qty"
                        sx={{
                          background: "#FFF",
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Stack
          direction={fullScreen ? "column" : "row"}
          justifyContent={"space-around"}
          alignItems={"center"}
          spacing={1}
          sx={{
            borderRadius: "8px",
            border: "0px 0px 1px 0px",
            background: "rgb(245, 255, 246)",
            width: "100%",
            padding: "10px",
            boxShadow: "2px 2px 2px 2px rgb(245, 255, 246)",
          }}
          item>
          <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            name="totalQty"
            label="Total Qty"
            value={state.sumQty}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            disabled
          />
          <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            name="subTotal"
            label="Sub Total"
            value={state.subTotal}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            disabled
          />

          <TextField
            id="outlined-basic"
            variant="outlined"
            name="prevBalance"
            size="small"
            disabled
            label="Previous Balance"
            value={state.prevBalance}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            onChange={handleChange}
          />

          {/* <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            name="total"
            disabled
            value={state.total}
            label="Total"
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            onChange={handleChange}
          /> */}

          {/* <TextField
            id="outlined-basic"
            variant="outlined"
            name="paid"
            type="number"
            size="small"
            label="Paid"
            value={state.paid}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            onChange={handleChange}
          /> */}

          <TextField
            id="outlined-basic"
            variant="outlined"
            name="balance"
            type="number"
            label="Balance"
            size="small"
            disabled
            value={state.balance}
            onChange={handleChange}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
          />

          <CustomBtn
            onClick={cancelSale}
            size="small"
            sx={{
              background: "red",
              color: "#FFF",
              width: "150px",
              "&:hover": {
                background: "red",
              },
            }}>
            Cancel Sale
          </CustomBtn>

          <CustomBtn
            size="small"
            onClick={makeBill}
            sx={{
              background: "#008890",
              color: "#FFF",
              width: "150px",

              "&:hover": {
                background: "#61D140",
              },
            }}>
            {customData._id ? "update Sale" : "Sale"}
          </CustomBtn>
        </Stack>
      </Grid>
    </>
  );
}

export default AddSale;
